import { devDebug } from "@/util/dev.mjs";
import globals from "@/util/global-whitelist.mjs";
import { APP_ROUTE_TYPE, APP_ROUTE_VERSION } from "@/util/mod-pathname.mjs";

// Register the service worker.
(async () => {
  if (!globals.navigator?.serviceWorker) return;

  if (globals.location.protocol !== "https:") {
    devDebug(
      "Skipping service worker registration because the page is not served over HTTPS.",
    );
    return;
  }

  const {
    navigator: { serviceWorker },
  } = globals;

  const SW_PATH = `/${
    APP_ROUTE_VERSION ? `v${APP_ROUTE_VERSION}/` : ""
  }service-worker.js`;

  const registrations = await serviceWorker.getRegistrations();

  for (const registration of registrations) {
    const { active } = registration;

    if (!active) continue;

    const { scriptURL } = active;

    if (!scriptURL.endsWith(SW_PATH)) {
      registration.unregister();
    }
  }

  const scope = APP_ROUTE_VERSION
    ? `/${APP_ROUTE_TYPE}${APP_ROUTE_VERSION}/`
    : "/";

  const registration = await serviceWorker.register(SW_PATH, {
    scope,
  });

  await registration.update();

  devDebug("service worker registered!");
})();
