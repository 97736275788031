import { setFeatureFlag } from "@/app/actions.mjs";
import { devDebug } from "@/util/dev.mjs";
import globals from "@/util/global-whitelist.mjs";

const KONAMI_SEQUENCE = [
  0x26, 0x26, 0x28, 0x28, 0x25, 0x27, 0x25, 0x27, 0x42, 0x41,
];

let currentIndex = 0;

if (typeof globals.addEventListener === "function") {
  globals.addEventListener("keydown", ({ keyCode }) => {
    if (KONAMI_SEQUENCE[currentIndex] !== keyCode) {
      currentIndex = 0;
      return;
    }
    currentIndex++;
    if (currentIndex === KONAMI_SEQUENCE.length) {
      complete();
      currentIndex = 0;
    }
  });
}

function complete() {
  devDebug("gamer mode activated 😎");
  setFeatureFlag("ads", false);
}
