import dbObj, {
  __PRIVATE_DB_SYMBOL_FOR_INTERNAL_USE_NOT_APPLICATION_CODE,
} from "@/__main__/db.mjs";

// Prevent malvertisers from abusing these.
/* eslint-disable no-restricted-properties */
delete globalThis.alert;
delete globalThis.confirm;
delete globalThis.prompt;

// Prevent third-parties from reading our cache.
// Let our app get a reference to indexedDB first.
const db = dbObj[__PRIVATE_DB_SYMBOL_FOR_INTERNAL_USE_NOT_APPLICATION_CODE];
db._events.once(db.DID_INIT, () => {
  delete globalThis.indexedDB;
});
